import { FC } from 'react'
import { useAuth } from '#context/auth'

export const ShowIfLoggedIn: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuth()
  if (!isAuthenticated) {
    return null
  }
  return <>{children}</>
}
