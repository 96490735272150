import styled from 'styled-components'

export const PageSectionPaginationContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.typePrimary};
  overflow-y: hidden;

  // pagination arrows
  > ul {
    padding-left: 1rem;
  }

  // Hide unwanted page numbers.
  .ant-pagination-item {
    display: none;
  }
`
