import { useRouter } from 'next/router'
import { useCallback } from 'react'

export const useQueryParams = () => {
  const router = useRouter()

  const getQueryParam = useCallback(
    (queryKey: string) => router.query[queryKey] as string | undefined,
    [router],
  )

  const setQueryParam = useCallback(
    (query: { [key: string]: string | null | undefined }) => {
      router.push(
        {
          pathname: router.pathname,
          query: { ...router.query, ...query },
          hash: router.asPath.split('#')[1],
        },
        undefined,
        { shallow: true },
      )
    },
    [router],
  )

  const replaceQueryParam = useCallback(
    (query: { [key: string]: string }) => {
      router.replace(
        {
          pathname: router.pathname,
          query: { ...router.query, ...query },
          hash: router.asPath.split('#')[1],
        },
        undefined,
        { shallow: true },
      )
    },
    [router],
  )

  const removeQueryParams = useCallback(
    (queryKeys: string[]) => {
      const newQuery = { ...router.query }
      queryKeys.forEach((queryKey) => delete newQuery[queryKey])
      router.replace(
        {
          pathname: router.pathname,
          query: newQuery,
          hash: router.asPath.split('#')[1],
        },
        undefined,
        { shallow: true },
      )
    },
    [router],
  )

  return {
    getQueryParam,
    setQueryParam,
    replaceQueryParam,
    removeQueryParams,
  }
}
