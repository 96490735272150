import { isNumber, NOT_FOUND } from '@netpurpose/types'
import { config } from '../config'

export const formatDollars = (decimal?: number) => {
  if (!isNumber(decimal)) {
    return NOT_FOUND
  }
  const formatted = decimal.toLocaleString(config.locale, { maximumFractionDigits: 0 })
  return `$${formatted}`
}
