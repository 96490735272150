import styled from 'styled-components'
import { Icon } from '../Icon'

export const BreadcrumbsContainer = styled.div`
  padding: 0.5rem 2.5rem;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.architecture3};
`

export const BreadcrumbText = styled.span`
  font-size: 0.75rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.typeSecondary};
  line-height: 1rem;
`

export const BreadcrumbLinkText = styled(BreadcrumbText)`
  text-decoration: underline;

  a {
    color: ${({ theme }) => theme.colors.typeSecondary};

    &:hover {
      color: ${({ theme }) => theme.colors.black};
    }
  }
`

export const Separator = styled(Icon)`
  margin: 0 0.75rem;
`
