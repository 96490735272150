import styled from 'styled-components'

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`
export const LoadingText = styled.div`
  font-weight: 400;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.typePrimary};
`
export const SpinnerContainer = styled.div`
  margin-top: 1.25rem;
`
