import { isNumber, NOT_FOUND } from '@netpurpose/types'
import { config } from '../config'

export const formatNumberWithThousandGroups = (
  decimal?: number | null,
  precision = 0,
  placeholderValue?: string,
) => {
  if (!isNumber(decimal)) {
    return placeholderValue || NOT_FOUND
  }

  return decimal.toLocaleString(config.locale, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  })
}
