import { Pagination } from 'antd'
import { isNumber, PaginationConfig } from '@netpurpose/types'
import { PageSectionPaginationContainer } from './PageSectionPagination.style'

const getItemsForPage = ({
  currentPage,
  totalCount,
  pageSize,
}: {
  currentPage: number
  totalCount: number
  pageSize: number
}) => {
  const multiplier = currentPage - 1
  const low = multiplier * pageSize + 1
  const high = Math.min((multiplier + 1) * pageSize, totalCount)
  return `${low}-${high}`
}

const PageSectionPagination = <
  PaginationProps extends Pick<
    PaginationConfig,
    'currentPage' | 'totalCount' | 'pageSize' | 'setCurrentPage'
  >,
>({
  pagination: { currentPage, totalCount, pageSize, setCurrentPage },
}: {
  pagination: PaginationProps
}) => {
  if (!isNumber(totalCount) || !isNumber(pageSize) || totalCount === 0) {
    return null
  }

  return (
    <PageSectionPaginationContainer>
      <span data-testid="page-section-pagination">
        {getItemsForPage({ currentPage, totalCount, pageSize })} of {totalCount}
      </span>
      {totalCount > pageSize && (
        <Pagination
          data-testid="page-section-pagination-controls"
          size="small"
          current={currentPage}
          onChange={(pageNum) => setCurrentPage(pageNum)}
          pageSize={pageSize}
          total={totalCount}
          showSizeChanger={false}
          showPrevNextJumpers={false}
        />
      )}
    </PageSectionPaginationContainer>
  )
}

export default PageSectionPagination
