import {
  AutoCompleteProps,
  Radio as FormikAntdRadio,
  Select as FormikAntdSelect,
  InputNumberProps,
  InputProps,
  PasswordProps,
  RadioGroupProps,
  SelectProps,
  TextAreaProps,
} from 'formik-antd'
import { ComponentProps, FC } from 'react'
import { DatePickerProps } from './FormikAntdDatePicker'
import {
  StyledAutoComplete,
  StyledCheckbox,
  StyledCheckboxWOutFormik,
  StyledDatePicker,
  StyledDatePickerWOutFormik,
  StyledInput,
  StyledInputWOutFormik,
  StyledNonGeneratedDatePickerWOutFormik,
  StyledNumberInput,
  StyledNumberInputWOutFormik,
  StyledPasswordInput,
  StyledRadio,
  StyledRadioWOutFormik,
  StyledSelect,
  StyledSelectWOutFormik,
  StyledTextArea,
} from './FormInput.style'

const { Option: FormikAntdSelectOption } = FormikAntdSelect
export const { Group: RadioGroup } = FormikAntdRadio

const withDefaultProps =
  <Props,>(Component: FC<Props>) =>
  (props: Props) => <Component size="large" {...props} />

export const Input = withDefaultProps<InputProps & ComponentProps<typeof StyledInput>>(StyledInput)
export const InputWOutFormik = StyledInputWOutFormik
export const PasswordInput = withDefaultProps<
  PasswordProps & ComponentProps<typeof StyledPasswordInput>
>(StyledPasswordInput)
export const TextArea = withDefaultProps<TextAreaProps & ComponentProps<typeof StyledTextArea>>(
  StyledTextArea,
)
export const NumberInput = withDefaultProps<
  InputNumberProps & ComponentProps<typeof StyledNumberInput>
>(StyledNumberInput)
export const Select = withDefaultProps<SelectProps & ComponentProps<typeof StyledSelect>>(
  StyledSelect,
)
export const NumberInputWOutFormik = StyledNumberInputWOutFormik
export const SelectOption = FormikAntdSelectOption
export const SelectWOutFormik = StyledSelectWOutFormik
export const DatePicker = withDefaultProps<
  DatePickerProps & ComponentProps<typeof StyledDatePicker>
>(StyledDatePicker)
export const DatePickerWOutFormik = StyledDatePickerWOutFormik
export const NonGeneratedDatePickerWOutFormik = StyledNonGeneratedDatePickerWOutFormik
export const Checkbox = StyledCheckbox
export const CheckboxWOutFormik = StyledCheckboxWOutFormik
export const AutoComplete = withDefaultProps<
  AutoCompleteProps & ComponentProps<typeof StyledAutoComplete>
>(StyledAutoComplete)
export const Radio = withDefaultProps<RadioGroupProps & ComponentProps<typeof StyledRadio>>(
  StyledRadio,
)
export const RadioWOutFormik = StyledRadioWOutFormik
