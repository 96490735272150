import {
  Checkbox as CheckboxWOutFormik,
  InputNumber as InputNumberWOutFormik,
  Input as InputWOutFormik,
  DatePicker as NonGeneratedDatePickerWOutFormik,
  Radio as RadioWOutFormik,
  Select as SelectWOutFormik,
} from 'antd'
import {
  AutoComplete as FormikAntdAutoComplete,
  Checkbox as FormikAntdCheckbox,
  Input as FormikAntdInput,
  InputNumber as FormikAntdNumberInput,
  Radio as FormikAntdRadio,
  Select as FormikAntdSelect,
} from 'formik-antd'
import styled from 'styled-components'
import { Theme } from '../../themes'
import { DatePickerWOutFormik } from './DatePickerWOutFormik'
import { FormikAntdDatePicker } from './FormikAntdDatePicker'

const { Password: FormikAntdPasswordInput, TextArea: FormikAntdTextArea } = FormikAntdInput

const sharedStyles = (theme: Theme) => `
  border-radius: ${theme.border.radiusSmall};
  width: 100%;
  color: ${theme.colors.typePrimary};
`

export const StyledNonGeneratedDatePickerWOutFormik = styled(NonGeneratedDatePickerWOutFormik)`
  border-radius: ${({ theme }) => theme.border.radius};
  width: 100%;
`

export const StyledDatePickerWOutFormik = styled(DatePickerWOutFormik)`
  border-radius: ${({ theme }) => theme.border.radiusSmall};
  width: 100%;
  height: 2.5rem;
`

export const StyledInput = styled(FormikAntdInput)`
  ${({ theme }) => sharedStyles(theme)}
`

export const StyledInputWOutFormik = styled(InputWOutFormik)`
  ${({ theme }) => sharedStyles(theme)}
  height: 2.5rem;
`

export const StyledNumberInputWOutFormik = styled(InputNumberWOutFormik)`
  ${({ theme }) => sharedStyles(theme)};

  .ant-input-number-input {
    height: 2.5rem;
    font-size: 1rem;
  }
`

export const StyledNativeInput = styled.input`
  ${({ theme }) => sharedStyles(theme)}
`

export const StyledPasswordInput = styled(FormikAntdPasswordInput)`
  ${({ theme }) => sharedStyles(theme)}
`

export const StyledTextArea = styled(FormikAntdTextArea)`
  ${({ theme }) => sharedStyles(theme)}

  // this is when char count is shown and styles get applied to wrapper component:
  textarea {
    ${({ theme }) => sharedStyles(theme)}
  }
`

export const StyledNumberInput = styled(FormikAntdNumberInput)`
  ${({ theme }) => sharedStyles(theme)}
`

export const StyledSelect = styled(FormikAntdSelect)`
  > .ant-select-selector.ant-select-selector {
    ${({ theme }) => sharedStyles(theme)}
  }
`
export const StyledSelectWOutFormik = styled(SelectWOutFormik)`
  > .ant-select-selector.ant-select-selector {
    ${({ theme }) => sharedStyles(theme)};
  }

  .ant-select-selection-item > div {
    /* Avoid unnecessary padding for selected item when options have some added
      e.g. see MetricLabel in DataByMetric.style.tsx */
    padding-left: 0;
  }

  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.typeSecondary};
  }
`

export const StyledDatePicker = styled(FormikAntdDatePicker)`
  ${({ theme }) => sharedStyles(theme)}
`

export const StyledAutoComplete = styled(FormikAntdAutoComplete)`
  > .ant-select-selector.ant-select-selector {
    ${({ theme }) => sharedStyles(theme)}
    pointer-events: auto;
  }
`

const sharedRadioStyles = (theme: Theme) => `
  border-radius: ${theme.border.radius};
  color: ${theme.colors.typePrimary};

  .ant-radio-checked .ant-radio-inner {
    background-color: ${theme.colors.actionPrimary};
  }
`

export const StyledRadio = styled(FormikAntdRadio)`
  ${({ theme }) => sharedRadioStyles(theme)}
`

export const StyledRadioWOutFormik = styled(RadioWOutFormik)`
  ${({ theme }) => sharedRadioStyles(theme)}
`

export const sharedCheckboxStyles = (theme: Theme, prefix = '', withBorder = true) => `
  .ant-${prefix}checkbox-inner {
    border-radius: ${theme.border.radiusSmall};
    border: ${withBorder ? `1px solid ${theme.colors.typeTertiary}` : '0'};
  }

  .ant-${prefix}checkbox-inner::after {
    background-color: ${theme.colors.actionPrimary};
    border-color: ${theme.colors.black};
  }

  .ant-${prefix}checkbox-checked .ant-${prefix}checkbox-inner {
    border: 1px solid ${theme.colors.black};
    background-color: ${theme.colors.actionPrimary};
  }

  .ant-${prefix}checkbox-disabled .ant-${prefix}checkbox-inner {
    border: 1px solid ${theme.colors.black};
    opacity: 0.5;
  }

  .ant-${prefix}checkbox:hover::after {
    visibility: hidden;
  }

`

export const StyledCheckboxWOutFormik = styled(CheckboxWOutFormik)`
  ${({ theme }) => sharedCheckboxStyles(theme)}
`

export const StyledCheckbox = styled(FormikAntdCheckbox)`
  ${({ theme }) => sharedCheckboxStyles(theme)}
`
