import { FC } from 'react'
import { ThemeColor } from '../../themes'
import { H1, H2, H3, H4, H5, H6 } from './Heading.style'

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6

interface Props {
  level: HeadingLevel
  color?: ThemeColor
  className?: string
  children?: React.ReactNode
}

const Heading: FC<Props> = ({ children, level, color, className }) => {
  const Component = [H1, H2, H3, H4, H5, H6][level - 1]

  if (!Component) {
    return null
  }

  return (
    <Component className={className} color={color}>
      {children}
    </Component>
  )
}

export default Heading
