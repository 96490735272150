import { isNumber, NOT_FOUND } from '@netpurpose/types'
import { config } from '../config'

export const formatNumberAsPercent = (decimal?: number | null, placeholderValue?: string) => {
  if (!isNumber(decimal)) {
    return placeholderValue || NOT_FOUND
  }

  const percentage = decimal.toLocaleString(config.locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  return `${percentage}%`
}

export const formatRatioAsPercent = (decimal?: number | null, placeholderValue?: string) => {
  if (!isNumber(decimal)) {
    return placeholderValue || NOT_FOUND
  }

  return formatNumberAsPercent(decimal * 100, placeholderValue)
}
