export const isEqual = (arr1: unknown[], arr2: unknown[]) => {
  return arr1.length === arr2.length && arr1.every((elem, index) => elem === arr2[index])
}

// Adds a new option if not already present, otherwise removes it.
export const updateSelectedOptions = <T>(option: T, currentlySelectedOptions: T[]): T[] => {
  if (currentlySelectedOptions.includes(option)) {
    const newArray = [...currentlySelectedOptions]
    newArray.splice(currentlySelectedOptions.indexOf(option), 1)
    return newArray
  }

  return [...currentlySelectedOptions, option].sort()
}

export const checkIfArraysAreDifferentUsingId = <T extends { id: number }>(
  arr1: T[],
  arr2: T[],
): boolean => {
  if (arr1.length !== arr2.length) {
    return true
  }

  const sortedArr1 = [...arr1].sort((a, b) => a.id - b.id)
  const sortedArr2 = [...arr2].sort((a, b) => a.id - b.id)

  // We can assume the arrays are the same length due to check above.
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i]?.id !== sortedArr2[i]?.id) {
      return true
    }
  }

  return false
}
