import { FC, ReactNode } from 'react'
import { PaginationConfig } from '@netpurpose/types'
import { Breadcrumb, Breadcrumbs, Heading, Loading, PageSectionPagination } from '..'
import {
  ActionsCol,
  LoadingContainer,
  NavigationCol,
  PageSectionContainer,
  PageSectionRow,
  SpacedContent,
  StyledDivider,
  Subtitle,
} from './PageSection.style'

type Props<
  ActionsProps extends Record<string, unknown>,
  TitleComponentProps extends Record<string, unknown>,
  DescriptionProps extends Record<string, unknown>,
> = {
  title?: string
  subtitle?: string | undefined
  children?: ReactNode
  description?: {
    Component?: FC<DescriptionProps>
    props: DescriptionProps
  }
  actions?: {
    Component: FC<ActionsProps>
    props: ActionsProps
  }
  headingLevel?: 1 | 2 | 3 | 4 | 5
  isSubsection?: boolean
  isLoading?: boolean
  paginationConfig?: PaginationConfig
  MultiSelectOptions?: FC
  titleComponent?: {
    Component?: FC<TitleComponentProps>
    props: TitleComponentProps
  }
  bottomBorder?: boolean
  reduceMargin?: boolean
  reduceTopSectionPadding?: boolean
  reduceContentGap?: boolean
  removeContentTopPadding?: boolean
  reduceContentTopPadding?: boolean
  breadcrumbs?: Breadcrumb[]
  wrapActionsForSmallerScreen?: boolean
  fullWidthActions?: boolean
}

export const PageSection = <
  ActionsProps extends Record<string, unknown>,
  TitleComponentProps extends Record<string, unknown>,
  DescriptionProps extends Record<string, unknown>,
>({
  title,
  subtitle,
  children,
  description,
  actions,
  headingLevel = 3,
  isSubsection = false,
  isLoading = false,
  paginationConfig,
  MultiSelectOptions,
  titleComponent,
  bottomBorder = false,
  reduceMargin = false,
  reduceTopSectionPadding = false,
  reduceContentGap = false,
  removeContentTopPadding = false,
  reduceContentTopPadding = false,
  breadcrumbs,
  wrapActionsForSmallerScreen = false,
  fullWidthActions = false,
}: Props<ActionsProps, TitleComponentProps, DescriptionProps>) => {
  const renderNavigationCol = Boolean(
    title || titleComponent?.Component || description?.Component || MultiSelectOptions,
  )

  const showPaginationControl = paginationConfig && !!paginationConfig?.totalCount

  return (
    <PageSectionContainer $bottomBorder={bottomBorder} className="page-section">
      {!!breadcrumbs?.length && <Breadcrumbs crumbs={breadcrumbs} />}
      <PageSectionRow
        $isSubsection={isSubsection}
        $wrapActionsForSmallerScreen={wrapActionsForSmallerScreen}
      >
        {renderNavigationCol ? (
          <NavigationCol
            $reduceMargin={reduceMargin}
            $hasSubtitle={!!subtitle}
            $reducePadding={reduceTopSectionPadding}
          >
            {title && <Heading level={headingLevel}>{title}</Heading>}
            {titleComponent?.Component && <titleComponent.Component {...titleComponent.props} />}
            {description?.Component && <description.Component {...description.props} />}
            {MultiSelectOptions && <MultiSelectOptions />}
          </NavigationCol>
        ) : (
          <div />
        )}
        {(actions || paginationConfig) && (
          <ActionsCol
            $isSubsection={isSubsection}
            $fullWidthActions={fullWidthActions}
            $wrapActionsForSmallerScreen={wrapActionsForSmallerScreen}
          >
            {actions?.Component && <actions.Component {...actions.props} />}
            {showPaginationControl && actions?.Component && <StyledDivider type="vertical" />}
            {showPaginationControl && <PageSectionPagination pagination={paginationConfig} />}
          </ActionsCol>
        )}
      </PageSectionRow>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {isLoading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <SpacedContent
          $reduceGap={reduceContentGap}
          $removeTopPadding={removeContentTopPadding || (isSubsection && renderNavigationCol)}
          $reduceContentTopPadding={reduceContentTopPadding}
        >
          {children}
        </SpacedContent>
      )}
    </PageSectionContainer>
  )
}
