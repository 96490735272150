import { FC } from 'react'
import Spinner from '../Spinner'
import { LoadingContainer, LoadingText, SpinnerContainer } from './Loading.style'

type Props = {
  withSpinner?: boolean
}

const Loading: FC<Props> = ({ withSpinner = true }) => (
  <LoadingContainer>
    <LoadingText>Loading data</LoadingText>
    {withSpinner && (
      <SpinnerContainer>
        <Spinner size="small" />
      </SpinnerContainer>
    )}
  </LoadingContainer>
)

export default Loading
