import { NOT_FOUND } from '@netpurpose/types'
import {
  np__schema__enum__QuestionType as QuestionType,
  TimeSeriesData,
  TimeSeriesRow,
} from '../../../generated/facts'
import { ReverseFieldMap } from '../../../queryBuilder'
import { Camelize } from '../../../utils'

const transformTimeSeriesDatapoint = <DatapointProps extends Camelize<TimeSeriesData>>({
  reported,
  estimated,
}: DatapointProps) => ({
  reported: reported
    ? {
        ...reported,
        value: reported?.value ?? NOT_FOUND,
        isNotDisclosed: reported?.isNd ?? false,
        isPending: reported?.isPending ?? false,
      }
    : undefined,
  estimated: estimated
    ? {
        ...estimated,
        value: estimated?.value ?? NOT_FOUND,
        isNotDisclosed: estimated?.isNd ?? false,
      }
    : undefined,
})

export type TimeSeriesDatapoint = Omit<ReturnType<typeof transformTimeSeriesDatapoint>, 'isNd'>

export const transformTimeSeriesData = <TimeseriesProps extends Camelize<TimeSeriesRow>>({
  questionId,
  questionType,
  questionDescription,
  nYears,
  datapoints,
  sdgGoals,
  ...rest
}: TimeseriesProps) => ({
  ...rest,
  questionId: Number(questionId),
  questionType,
  ...(questionDescription && { questionDescription }),
  numYears: nYears,
  datapoints: datapoints.map(transformTimeSeriesDatapoint),
  sdgGoals,
})

export type TimeSeriesFact = Omit<
  Camelize<TimeSeriesRow>,
  'questionId' | 'questionType' | 'nYears' | 'datapoints'
> & {
  questionType: QuestionType
  questionId: number
  numYears: number
  datapoints: TimeSeriesDatapoint[]
}

export const reverseTimeSeriesFieldMap: ReverseFieldMap<
  keyof TimeSeriesFact | 'dataType' | 'includeSdgAlignment' | 'searchTerm'
> = {
  displayThemes: 'theme', // BE still expects a theme
  questionName: 'question.name',
  questionUnit: 'unit.name',
  dataType: {
    key: 'data_type',
    transform: (dataType) => (dataType === 'Standardised' ? 'Calculated' : dataType),
  },
  numYears: 'n_years',
  datapoints: 'datapoints',
  finalYear: 'final_year',
  questionDescription: 'question_description',
  questionId: 'question_id',
  questionType: 'question_type',
  includeSdgAlignment: 'include_sdg_alignment',
  sdgGoals: 'sdg_goal',
  searchTerm: 'search_term',
}
