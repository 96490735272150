import { FC } from 'react'
import { NextLink } from '../NextLink'
import {
  BreadcrumbLinkText,
  BreadcrumbsContainer,
  BreadcrumbText,
  Separator,
} from './Breadcrumbs.style'

export type Breadcrumb = {
  name: string
  href?: string
  onClick?: () => void
}

type Props = { crumbs: Breadcrumb[] }

const Crumb: FC<{ crumb: Breadcrumb }> = ({ crumb: { name, href, onClick = () => {} } }) =>
  href ? (
    <BreadcrumbLinkText>
      <NextLink href={href} onClick={onClick}>
        {name}
      </NextLink>
    </BreadcrumbLinkText>
  ) : (
    <BreadcrumbText>{name}</BreadcrumbText>
  )

const renderCrumbs = (crumbs: Breadcrumb[]) => {
  if (crumbs.length < 2) {
    return crumbs.map((crumb) => <Crumb crumb={crumb} />)
  }

  return crumbs.map((crumb, index) =>
    index === 0 ? (
      <Crumb crumb={crumb} key={crumb.name} />
    ) : (
      [
        <Separator
          key={`${crumb.name}Separator`}
          icon="BreadcrumbSeparator"
          alt="Breadcrumb separator"
          color="typeSecondary"
          size="xs"
        />,
        <Crumb crumb={crumb} key={crumb.name} />,
      ]
    ),
  )
}

export const Breadcrumbs: FC<Props> = ({ crumbs }) => (
  <BreadcrumbsContainer>{renderCrumbs(crumbs)}</BreadcrumbsContainer>
)
