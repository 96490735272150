import styled from 'styled-components'
import { ThemeColor } from '../../themes'

type Props = {
  color: ThemeColor
  size?: 'normal' | 'small'
}

export const ColoredCircle = styled.div<Props>`
  display: inline-block;
  width: ${({ size = 'normal' }) => (size === 'small' ? '0.5rem' : '0.625rem')};
  height: ${({ size = 'normal' }) => (size === 'small' ? '0.5rem' : '0.625rem')};
  border-radius: 50%;
  background-color: ${({ theme, color }) => theme.colors[color]};
`
